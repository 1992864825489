import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import NoLayout from '../components/layout/nolayout'

const PrivacyNoLayout = ({ data }) => (
  <NoLayout>
    <div dangerouslySetInnerHTML={{ __html: data.iubendaDocument.privacyPolicy }} />
  </NoLayout>
)

PrivacyNoLayout.propTypes = {
  data: PropTypes.shape({
    iubendaDocument: PropTypes.shape({
      privacyPolicy: PropTypes.string,
    }),
  }).isRequired,
}

export default PrivacyNoLayout

export const query = graphql`
query PrivacyNoLayout {
  iubendaDocument(documentId: {eq: "28834528"}) {
    privacyPolicy
  }
}
`
